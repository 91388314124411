<template>
  <div>
    <div>
      <el-image fit="contain" :src="abouts" />
    </div>
    <el-breadcrumb separator="/" style="margin-left:10px; margin-top:10px">
      <el-breadcrumb-item :to="{ path: '/' }">
        {{ $t('Nav.home') }}
      </el-breadcrumb-item>
      <el-breadcrumb-item> {{ $t('Nav.cpfa') }} </el-breadcrumb-item>
    </el-breadcrumb>
    <div style="position: relative;clear: both;height:344rem">
      <!-- <el-image fit="contain" :src="itc" style="width:9rem; height:6rem" /> -->
      <div style="position:absolute;top:2rem; ">
        <router-link :to="{ path: '/components/mobile/chanpin-1' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc1"
              style="width:100%;margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px; line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.sdhujuzhongxin') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-2' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc2"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px;height:30px; line-height:30px; background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case02_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-3' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc3"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case03_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-4' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc4"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case04_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-5' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc5"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case05_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-6' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc6"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case06_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-7' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc7"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case07_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-8' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc8"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case08_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-9' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc9"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case09_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-10' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc10"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case10_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-11' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc11"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case11_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-12' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc12"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case12_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-13' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc13"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case13_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-14' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc14"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case14_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-15' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc15"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case15_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-16' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc16"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case16_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-17' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc17"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case17_1') }}
            </div>
          </div>
        </router-link>
        <router-link :to="{ path: '/components/mobile/chanpin-18' }">
          <div style="position: relative;">
            <el-image
              fit="contain"
              :src="itc18"
              style="width:100%; margin-bottom:20px"
            />
            <div
              style="width:100%;position:absolute; bottom:24px; height:30px;line-height:30px;background:#393c5b; color:#fff;"
            >
              {{ $t('Nav.case18_1') }}
            </div>
          </div>
        </router-link>
      </div>
    </div>

    <div style="clear: both; overflow: hidden;margin-bottom:20px;"></div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Lazyload } from 'vant'
Vue.use(Lazyload)
export default {
  name: 'itc',
  components: {
    // HelloWorld
  },
  data() {
    return {
      abouts: require('../../assets/index/03.jpg'),
      ict: require('../../assets/mobile/07-img@3x.png'),
      point: require('../../assets/point.gif'),
      itc1: require('../../assets/chanpin/case01.jpg'),
      itc2: require('../../assets/chanpin/case02.jpg'),
      itc3: require('../../assets/chanpin/case03.jpg'),
      itc4: require('../../assets/chanpin/case04.png'),
      itc5: require('../../assets/chanpin/case05.png'),
      itc6: require('../../assets/chanpin/case06.png'),
      itc7: require('../../assets/chanpin/case07.png'),
      itc8: require('../../assets/chanpin/case08.png'),
      itc9: require('../../assets/chanpin/case09.png'),
      itc10: require('../../assets/chanpin/case10.jpg'),
      itc11: require('../../assets/chanpin/case11.png'),
      itc12: require('../../assets/chanpin/case12.png'),
      itc13: require('../../assets/chanpin/case13.jpg'),
      itc14: require('../../assets/chanpin/case14.jpg'),
      itc15: require('../../assets/chanpin/case15.jpg'),
      itc16: require('../../assets/chanpin/case16.jpg'),
      itc17: require('../../assets/chanpin/case17.jpg'),
      itc18: require('../../assets/chanpin/case18.jpg'),
    }
  },
  methods: {},
}
</script>

<style></style>
